import { mapGetters } from 'vuex';

const rolesComputedGeneratorList = (entity) => ({
  canAccessPage() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .find((el) => el === `${entity}_PAGINA` || el === `${entity}_CRUD`
        || el === `${entity}_AGENCIA` || el === `${entity}_EMPRESA`
        || el === `${entity}_PAGINA_APROVADO`);
  },
  canAccessCRUD() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .filter((el) => el === `${entity}_CRUD`).length;
  },
  canEnable() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .filter((el) => el === `${entity}_ATIVAR` || el === `${entity}_CRUD`).length;
  },
  canPost() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .filter((el) => el === `${entity}_INSERIR` || el === `${entity}_CRUD`).length;
  },
  canGet() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .filter((el) => el === `${entity}_OBTER` || el === `${entity}_CRUD`
        || el === `${entity}_PAGINA`).length;
  },
  canEdit() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .filter((el) => el === `${entity}_ATUALIZAR` || el === `${entity}_CRUD`).length;
  },
  canSearch() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .filter((el) => el === `${entity}_PAGINA` || el === `${entity}_PESQUISAR`
        || el === `${entity}_CRUD`).length;
  },
  canImport() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .filter((el) => el === `${entity}_IMPORTAR` || el === `${entity}_CRUD`).length;
  },
  canExport() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .filter((el) => el === `${entity}_EXPORTAR`).length;
  },
  canHistory() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .filter((el) => el === `${entity}_PESQUISAR_LOG` || el === `${entity}_PAGINA`
        || el === `${entity}_CRUD`).length;
  },
  canCopy() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .filter((el) => el === `${entity}_COPIAR` || el === `${entity}_CRUD`).length;
  },
  canCancel() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .filter((el) => el === `${entity}_CANCELAR` || el === `${entity}_CRUD`).length;
  },
  canStartWorkflow() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .filter((el) => el === `${entity}_INICIA_FLUXO` || el === `${entity}_CRUD`).length;
  },
  canFinish() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .filter((el) => el === `${entity}_FINALIZAR` || el === `${entity}_CRUD`).length;
  },
  canRecover() {
    if (!this.getAllRoles) {
      return false;
    }
    return !!this.getAllRoles
      .filter((el) => el === `${entity}_RECUPERAR`).length;
  },
});

const appendSuffixOnListKey = (list, key, suffix) => ({ [`${key}${suffix}`]: list[key] });

const compilerolesComputedGeneratorList = (list, selectionList, suffix = '') => {
  if (!selectionList.length) {
    return list;
  }
  return Object.keys(list)
    .reduce((acc, curr) => {
      if (selectionList.indexOf(curr) !== -1) {
        return Object.assign(appendSuffixOnListKey(list, curr, suffix), acc);
      }
      return acc;
    }, {});
};

/*
 * Arguments:
 *  param[0]: String, required
 *  param[1]: [String, mixed]
 *  param[2]: Array
 * Example: generateComputed('Produto', 'SomeSuffix', ['canUpload']);
 * Will produce:
 *  {
 *    getRoles() { ... },
 *    canUploadSomeSuffix() { ... },
 *  }
 */
export const generateComputed = (...params) => {
  if (!params || !params.length) {
    return {};
  }

  const entity = (params[0]).replace(/[' ']/g, '_').toUpperCase(),
    suffix = (typeof params[1] === 'string')
      ? params[1]
      : '';

  let list = [];
  if (typeof params[1] === 'object') {
    // eslint-disable-next-line prefer-destructuring
    list = params[1];
  } else if (typeof params[2] === 'object') {
    // eslint-disable-next-line prefer-destructuring
    list = params[2];
  }
  return ({
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...compilerolesComputedGeneratorList(rolesComputedGeneratorList(entity), list, suffix),
  });
};

export default generateComputed;
